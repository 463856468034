import { Card, CardBody, CardFooter, Text } from "@chakra-ui/react";
import { FaRegCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const CardDetail = ({ material }) => {
  return (
    <Link to={`/materiales/${material.tipo}/${material.urlTitulo}`}>
      <Card>
        <CardBody className="tarjeta" padding={0}>
          <img src={material.urlImagen} alt="" className="tarjeta-imagen" />
        </CardBody>
        <CardFooter
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {material.premium && (
              <FaRegCircle style={{ marginRight: "8px", color: "red" }} />
            )}
            <Text>{material.titulo}</Text>
          </div>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default CardDetail;
