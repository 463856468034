import Layout from "../../containers/Layout";
import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";
import DualTextVideoTemplate from "../DualTextVideoTemplate/DualTextVideoTemplate";
import "./ProfessionalJourneyEmail.css";

const ProfessionalJourneyEmail = () => {
  return (
    <Layout mt={16}>
      <DualTextVideoTemplate
        mainTitle="WHAT MY STUDENTS HAVE TO SAY ABOUT ME"
        buttonLabel="Telegram Group"
        linkPath="https://t.me/+S78OquqBTZdmNGNk"
        showButton={false}
        leftText="letfTExt en un lugar de la mancha de cuyo nombre no quiero acordamre"
        rightText="letfTExt había un veijo rocinante que no sabía más que leer libros de caballerías"
        videoUrl="https://iframe.mediadelivery.net/play/245875/7dd5d9a0-f3cd-4748-b027-23307af5bcf1"
      />

      <BoxInfoTemplate
        title=""
        subTitle="Milestone achieved!"
        mainText="You did it! Congratulations on completing the challenge. 💪"
        iconType="redDot"
        showQuotes={true}
        showShadow={true}
        borderColor="orange"
      />
      <BoxInfoTemplate
        title="CRITICAL ERROR"
        mainText="Nunca digas: (estoy caliente)."
        iconType="alert"
        borderColor="red"
      />
      <BoxInfoTemplate
        title=""
        mainText="Operación completada."
        iconType="check"
        borderColor="green"
        showQuotes={false}
      />
      <BoxInfoTemplate
        title=""
        mainText="Día a día, verbo a verbo"
        iconType="thumbUp"
        borderColor="blue"
      />
      <BoxInfoTemplate
        title=""
        mainText="New features released."
        iconType="alarm"
        borderColor="brown"
      />
      <BoxInfoTemplate
        title=""
        mainText="Level up!"
        iconType="trophy"
        borderColor="gold"
      />
      <BoxInfoTemplate
        title="CRITICAL ERROR"
        mainText="No digas: (estoy caliente) "
        iconType="alarm"
        borderColor="red"
        showQuotes={false}
      />
      <BoxInfoTemplate
        title=""
        mainText="Great! You nailed it! Keep it up."
        iconType="thumbUp"
        borderColor="blue"
        showQuotes={false}
      />
      <BoxInfoTemplate
        title="ATENCION"
        mainText=" (es bien) No existe en español."
        iconType="alarm"
        borderColor="red"
        showQuotes={false}
      />
      <BoxInfoTemplate
        title=""
        mainText="Pasito a pasito hacemos camino. No te preocupes si vamos despacito."
        iconType="redDotGafasSonrisa"
        borderColor="orange"
        showQuotes={true}
      />
      <BoxInfoTemplate
        title=""
        mainText="Equivocarse es bonito"
        iconType="lightning"
        borderColor="orange"
      />

      <BoxInfoTemplate
        title=""
        mainText="Ser inexpresivo es poco comunicativo."
        iconType="meh"
        borderColor="gray"
        showQuotes={true}
      />
      <BoxInfoTemplate
        title="Celebrate!"
        mainText="New milestone achieved."
        iconType="party"
        borderColor="pink"
      />
      <BoxInfoTemplate
        title="Update Required"
        mainText="Please update your software."
        iconType="thumbDown"
        borderColor="teal"
      />
      <BoxInfoTemplate
        title=""
        mainText="La mejor politica es la del poco a poco."
        iconType="info"
        borderColor="cyan"
      />
      <BoxInfoTemplate
        title=""
        mainText="Please check your email."
        iconType="battery"
        borderColor="orange"
      />
      <BoxInfoTemplate
        title="Deadline!"
        mainText="Submission due today."
        iconType="sun"
        borderColor="red"
      />
      <BoxInfoTemplate
        title="New Message"
        mainText="You have received a new message."
        iconType="check"
        borderColor="blue"
      />
      <BoxInfoTemplate
        title="Security Alert!"
        mainText="Potential security breach detected."
        iconType="shield"
        borderColor="black"
      />
      <BoxInfoTemplate
        title="File Uploaded"
        mainText="Your file was successfully uploaded."
        iconType="upload"
        borderColor="green"
      />
      <BoxInfoTemplate
        title="TEMPERATURE WARNING"
        mainText="Alert! High temperature detected. Drink some water"
        iconType="alert"
        borderColor="red"
        showQuotes={false}
        showShadow={false}
      />
      <div className="email-container">
        <h1 className="email-header">Hola amigos!😃</h1>
        <div className="email-content">
          <p>
            You might wonder why someone chooses a professional path like
            teaching, speaking with foreigners all day from their home. The
            short answer is that it was inevitable. From a young age, I was
            destined to be a teacher, and this became overwhelmingly clear as I
            grew older.
          </p>

          <p>
            When I started my education science degree, I found my calling. What
            a joy it is to know what you want to do in life! In pedagogy, you
            learn to gather data to improve educational practice, look for
            techniques to enhance classes, and support other teachers, among
            other things.
          </p>

          <p>
            Perhaps that's why in my classes, I love listening to you and giving
            you the confidence to speak with motivation and without
            embarrassment. This is very important to me.
          </p>

          <p>
            After many years as a teacher, I believe that one of the most
            important things as an educator is to truly LISTEN to the student...
            Trying to understand what you need, what you want, and how I can
            adapt to your needs by making classes fun and motivating.
          </p>

          <p>
            But teaching hasn't been my only profession. I've also been a
            lifeguard, a projectionist, a swimming coach, and a croupier, among
            others. Every job I've had, every hobby I've pursued, and every
            friend I've made, I believe, has defined me a little more as a
            person and has shaped me into the teacher I am today.
          </p>

          <p className="signature">
            Sending you a teacher's hug, my friend, and I'll see you in the next
            podcast. Bye!
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ProfessionalJourneyEmail;
