import {
  Box,
  Container,
  Image,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import HeadingSection from "../commons/HeadingSection/HeadingSection";
import Review from "../Review/Review";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ReviewCarousel = () => {
  const [reviews, setReviews] = useState([]);
  // const [error, setError] = useState(null);
  const { colors } = useTheme();
  const [isSmallScreen] = useMediaQuery("(max-width: 1200px)");

  // Calculate the number of elements to show based on screen size
  const elementsToShow = isSmallScreen ? 1 : 1;

  useEffect(() => {
    axios
      .get("/api/reviews")
      .then((res) => {
        setReviews(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box
      bg={useColorModeValue(colors.light.bgPrimary, colors.dark.bgPrimary)}
      position="relative"
    >
      <Image
        src="/images/reviewd_shape.svg"
        alt="Shape bubble"
        zIndex={1}
        position="absolute"
        top={0}
        width="100%"
        height="100%"
        right={{ base: "0", md: "400px" }}
      />
      <Container my="4" maxW={{ base: "container.sm", md: "6xl" }}>
        <Box position="relative" zIndex={1}>
          <HeadingSection title="" sub="REVIEWS" />
          <Carousel
            infiniteLoop
            showStatus={false}
            showArrows={!isSmallScreen}
            showThumbs={false}
            centerMode={!isSmallScreen}
            emulateTouch={!isSmallScreen}
            swipeScrollTolerance={!isSmallScreen ? 2 : 10}
            centerSlidePercentage={100 / elementsToShow}
            showSides={!isSmallScreen}
            selectedItem={0}
            interval={5000}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const icon = isSelected ? "●" : "○";
              return (
                <span
                  style={{
                    marginLeft: 20,
                    color: "grey",
                    cursor: "pointer",
                    fontSize: "24px", // Increase the font size for larger circles
                    padding: "5px", // Optional: Add some padding for better clickability
                  }}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                  {icon}
                </span>
              );
            }}
          >
            {reviews &&
              reviews.map((review) => (
                <Review key={review._id} review={review} />
              ))}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
};

export default ReviewCarousel;
