import { extendTheme } from '@chakra-ui/react'
import "@fontsource/comic-mono";
import "@fontsource/courier-prime";

const theme = extendTheme({
  colors: {
    light: {
      bgPrimary: "#fefffe",
      bgTranspLight: `rgba(254, 255, 254, 0.8)`,
      bgSecondary: "#f3f3f3",
      border: "#b6b6b6",
    },
    dark: {
      bgPrimary: "#020919",
      bgTranspDark: `rgba(2, 9, 25, 0.8)`,
      bgSecondary: "#0d1321",
      border: "#30353f",
    },
  },
  fonts: {
    body: "'Courier Prime', monospace",
    heading: "'Courier Prime', monospace", // Optional: Use it for headings as well
    // You can also add it as a custom font style
    comicMono: "'Comic Mono', monospace",
    courierPrime: "'Courier Prime', monospace",
  },
  styles: {
    global: {
      // Define la animación de fade-in
      "@keyframes fadeIn": {
        from: { opacity: 0 },
        to: { opacity: 1 },
      },
      // Aplica la animación de fade-in a una clase específica
      ".fade-in": {
        animation: "fadeIn 1s ease-in-out",
      },
    },
  },
});

export default theme;
