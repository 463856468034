import React from "react";
import { Icon, Image } from "@chakra-ui/react";
import {
  FaExclamationTriangle,
  FaRegGrin,
  FaRegSadCry,
  FaRegSmileWink,
  FaRegMeh,
  FaRegLaugh,
  FaCoffee,
  FaAppleAlt,
} from "react-icons/fa";
import { AiOutlineSmile, AiOutlineWarning, AiFillHeart } from "react-icons/ai";
import {
  GiCoolSpices,
  GiFireFlower,
  GiNinjaHead,
  GiTrophy,
} from "react-icons/gi";
import {
  MdSentimentSatisfied,
  MdSentimentDissatisfied,
  MdThumbUp,
  MdThumbDown,
} from "react-icons/md";
import { FiAlertCircle, FiCheckCircle, FiInfo } from "react-icons/fi";
import { BsSun, BsMoon, BsLightning } from "react-icons/bs";

const IconSelector = ({ iconType }) => {
  switch (iconType) {
    case "alarm":
      return <Icon as={FaExclamationTriangle} w={10} h={10} color="red.500" />; // 🚨
    case "drawing":
      return <Icon as={AiOutlineSmile} w={10} h={10} color="blue.500" />; // 😀
    case "happy":
      return <Icon as={FaRegGrin} w={10} h={10} color="green.500" />; // 😁
    case "sad":
      return <Icon as={FaRegSadCry} w={10} h={10} color="blue.300" />; // 😢
    case "cool":
      return <Icon as={GiCoolSpices} w={10} h={10} color="purple.500" />; // 😎
    case "thinking":
      return (
        <Icon as={MdSentimentSatisfied} w={10} h={10} color="yellow.500" />
      ); // 🤔
    case "disappointed":
      return (
        <Icon as={MdSentimentDissatisfied} w={10} h={10} color="gray.500" />
      ); // 😞
    case "wink":
      return <Icon as={FaRegSmileWink} w={10} h={10} color="pink.400" />; // 😉
    case "meh":
      return <Icon as={FaRegMeh} w={10} h={10} color="gray.600" />; // 😐
    case "laugh":
      return <Icon as={FaRegLaugh} w={10} h={10} color="orange.500" />; // 😂
    case "coffee":
      return <Icon as={FaCoffee} w={10} h={10} color="brown.500" />; // ☕
    case "apple":
      return <Icon as={FaAppleAlt} w={10} h={10} color="red.600" />; // 🍎
    case "flower":
      return <Icon as={GiFireFlower} w={10} h={10} color="red.400" />; // 🔥
    case "ninja":
      return <Icon as={GiNinjaHead} w={10} h={10} color="black" />; // 🥷
    case "trophy":
      return <Icon as={GiTrophy} w={10} h={10} color="gold" />; // 🏆
    case "heart":
      return <Icon as={AiFillHeart} w={10} h={10} color="red.500" />; // ❤️
    case "warning":
      return <Icon as={AiOutlineWarning} w={10} h={10} color="orange.600" />; // ⚠️
    case "alert":
      return <Icon as={FiAlertCircle} w={10} h={10} color="red.600" />; // 🚨
    case "check":
      return <Icon as={FiCheckCircle} w={10} h={10} color="green.500" />; // ✔️
    case "info":
      return <Icon as={FiInfo} w={10} h={10} color="blue.400" />; // ℹ️
    case "sun":
      return <Icon as={BsSun} w={10} h={10} color="yellow.500" />; // ☀️
    case "moon":
      return <Icon as={BsMoon} w={10} h={10} color="gray.700" />; // 🌙
    case "lightning":
      return <Icon as={BsLightning} w={10} h={10} color="yellow.300" />; // ⚡
    case "thumbUp":
      return <Icon as={MdThumbUp} w={10} h={10} color="blue.500" />; // 👍
    case "thumbDown":
      return <Icon as={MdThumbDown} w={10} h={10} color="red.500" />; // 👎
    case "redDot":
      return (
        <Image src="/images/puntoRojo1.png" w={10} h={10} alt="Punto Rojo" />
      ); // Imagen de punto rojo
    case "redDotGafasSonrisa":
      return (
        <Image
          src="/images/redDotGafasSonrisa.png"
          w={10}
          h={10}
          alt="Punto Rojo"
        />
      ); // Imagen de punto rojo
    default:
      return null;
  }
};

export default IconSelector;
