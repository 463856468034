import React from 'react';
import './GuidePronouncing.css'; // Estilo específico para el correo de bienvenida

const GuidePronouncing = () => {
  return (
    <div className="email-container">
      <h1 className="email-header">Hey there! You've subscribed! Welcome, 😃</h1>

      <div className="email-content">
        <p className="first-paragraph">
          And thank you<br />
          Alright... Where do we begin? Well, let's start with me.<br />
          😊 My name is Alex Rodríguez, and I'm not a philologist or a linguist. I am a pedagogist, and most importantly, I want to be the best teacher possible.👑
        </p>

        <p>
          What does all this education mean? Well, to be honest, not much. You learn more about education in the classroom, but it always gives you a head start. Also, for us to have classes together, it's important we get on well together. And you are going to get to know me. Because we're going to talk, and talk a lot. And I'll share a bit about my life :) Well... maybe a lot...
        </p>

        <p>
          In all these years as a teacher, I've taught: Teachers, children, adults, the elderly, famous kings, animals, family, and myself. Learning and teaching 24/7 for more than 20 years.
        </p>

        <p>
          I believe my job goes beyond teaching you heavy grammar; I want to help you communicate in Spanish. And it's an honor to help you stay motivated. Here's a sneak peek of your welcome gift!  (my guide for pronouncing) P.S. Here's the link I mentioned: <a href="http://spanishwithalex.com/login" className="highlight">spanishwithalex.com/login</a>
        </p>

        <p>
          See you in the next email!<br />
          Big teacher hug, Alex
        </p>

        <p className="signature">
          P.S. I'll write to you again soon. Definitely. And ask you a few things. :
        </p>
      </div>
    </div>
  );
};

export default GuidePronouncing;
