import Header from "../Header/Header";
import HorizontalCardIlustrations from "../HorizontalCardIlustrations/HorizontalCardIlustrations";
import HorizontalCardPrices from "../HorizontalCardPrices/HorizontalCardPrices";
import ConversationZoom from "../ConversationZoom/ConversationZoom";
import ContactCard from "../ContactCard/ContactCard";
import Video from "../Video/Video";
import ReviewCarousel from "../ReviewCarousel/ReviewCarousel";
import CarouselMateriales from "../CarouselMateriales/CarouselMateriales";
import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";

const Main = ({ materiales }) => {
  return (
    <main>
      <Header materiales={materiales} />
      <CarouselMateriales materiales={materiales} />
      <Video />
      <ReviewCarousel />
      <HorizontalCardIlustrations />
      <BoxInfoTemplate
        title=""
        subTitle=""
        mainText="Don’t let this opportunity slip away, tío!"
        iconType="redDot"
        showQuotes={true}
        showShadow={true}
        borderColor="orange"
      />
      <HorizontalCardPrices />
      <ConversationZoom />
      <ContactCard />
    </main>
  );
};

export default Main;
