import Layout from '../../containers/Layout';
import './WelcomeEmail.css'; // Estilo específico para el correo de bienvenida

const WelcomeEmail = () => {
  return (
        <Layout mt={16}>

    <div className="email-container">
      <h1 className="email-header">Hey there! You've subscribed! Welcome, 😃</h1>

      <div className="email-content">
        <p >
          And first off, let me say that you are awesome, your motivation to learn a language is something amazing. 
          Learning a language is a truly challenging experience, and my intention is to help you learn Spanish the best way possible.<br />
          What does all this mean? I am here to create content for you.
          You are going to find videos and helpfull exercices. 
          I hope It works for you.
        </p>
        <p>
          I think that my job goes beyond teaching you heavy grammar; and I want to help you communicate in Spanish while having fun.
            And it will be an honor to help you stay motivated.
           
        </p>

        <p>
          In all these years as a teacher, I've taught: Teachers, children, adults, actors, royals, animals, family, myself and now is your turn.
          buckle up! :)
        </p>

        

        <p>
         Thank you and See you in the next email!<br />
           Big hug, Alex
        </p>

        <p>Here's a welcome gift!  (my guide for pronouncing) Follow the link: <a href="http://spanishwithalex.com/guide-pronouncing" className="highlight">spanishwithalex.com/guide-pronouncing</a></p>

        <p className="signature">
         "Today is a good day to learn something new." SwA

        </p>
      </div>
    </div>
    </Layout>
  );
};

export default WelcomeEmail;
